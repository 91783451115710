@import "../../../node_modules/@angular/material/theming";

@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import '~@syncfusion/ej2-lists/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-richtexteditor/styles/material.css';
@import '~@syncfusion/ej2-angular-popups/styles/material.css';
@import '~@syncfusion/ej2-angular-diagrams/styles/material.css';

@import 'assets/theme/aviita-vwbp/theme-light';


@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: url("assets/layout/fonts/OpenSans-Regular.woff2") format("woff2");
  }


$fontName: Open Sans, sans-serif;

// @import '~ngx-smart-modal/ngx-smart-modal';

//  @import 'assets/theme/theme-grey.scss';

/* You can add global styles to this file, and also import other style files */

// $primaryColor: #111111;
$headerBackgroundColor: #fff;

:root {
    --form-field-background: #fafafa;
    --header-height: 100px;
}

a {
    cursor: pointer;
}

.force-pointer {
    cursor: pointer;
}

// Überschreibt die Material-Font
$custom-typography: mat-typography-config(
    $font-family: $fontName,
);

@include angular-material-typography($custom-typography);

// Überschreibt die Syncfustion-Font
.e-control,
.e-tab .e-toolbar-items .e-toolbar-item .e-tab-text,
.e-card {
    font-family: $fontName;
}


#header-title-container {
    left: 300px;
    font-size: 1.1em;
    color: white;
    position: absolute;
    top: 23px;
}

#header-title-container a {
    color: white;
}

@media (max-width: 641px) {
    #header-title-container {
        display: none;
    }
}

.vwb-public-container,
.vwb-internal-container-inaktiv {
    max-width: 1300px;
    margin: 0 auto;
}

.loader-div-with-text {
    font-size: larger;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
}


p-panel > div,
.vwb-visible-box {
    // box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

[hidden] {
    display: none !important;
}

h1.pageTitle {
    color: 333;
    font-size: 1.3em;
    font-weight: normal;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #666;
}

h1.pageTitleNoBorder {
    color: 333;
    font-size: 1.3em;
    font-weight: normal;
}

h2.pageSubTitle {
    color: 333;
    font-size: 0.9em;
    font-weight: normal;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #666;
}

h2.default {
    color: $textColor;
    font-size: 1.2em;
    line-height: 1.5em;
    font-weight: normal;
}

h3 {
    margin-top: 0px;
}

.align-self-end {
    align-self: self-end;
}

.p-toolbar .p-divider {
    padding: 0;
    margin: 0 0.4rem;
}

.toolbar-divider .p-divider.p-divider-horizontal {
    margin: 0.5rem 0 1rem 0;
    /* padding: 0 0.5rem; */
}

.summary-header {
    font-weight: bold;
    color: #062b70;
}

.pos-rel {
    position: relative;
}

.pull-right {
    float: right;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.label-right {
    text-align: right;
}

.color-red {
    color: red !important;
}

.color-orange {
    color: orange !important;
}
.color-blue {
    color: blue !important;
}

.color-green {
    color: green !important;
}

.color-grey {
    color: grey !important;
}

.small-light {
    font-size: small;
    color: #666;
}

.text-small {
    font-size: small;
}

.text-xsmall {
    font-size: x-small;
}

.layout-container .topbar {
    // background-color: #cc7000;
}

.layout-container .topbar .topbar-left {
    // background-color: #cc7000;
}

.layout-container .topbar .topbar-right #menu-button {
    background-color: #666;
}

@media (max-width: 641px) {
    .label-right {
        text-align: left;
    }
}

.p-datatable.p-datatable-resizable .p-datatable-thead > tr > th {
    overflow: visible;
}

.searcher-autofilter-color {
    background-color: white !important;
}

input.searcher-filter,
p-calendar.searcher-filter input,
p-dropdown.searcher-filter label:not(.ui-placeholder),
.searcher-filter .multiselect-item-value {
    color: #e32d3d;
}

body .p-toolbar {
    background-color: #fbfbfb;
    color: black;
    border: 0px solid #fbfbfb;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

body .p-paginator {
    background-color: #fbfbfb;
}

body .p-paginator > a {
    box-sizing: border-box;
    color: #444;
}

body .ui-paginator .ui-paginator-pages a {
    color: #444;
}

.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    min-width: 2rem;
    height: 2rem;
}

body .ui-widget-header {
    background-color: white;
    color: #333;
    font-size: 1em;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

body .p-panel .p-panel-titlebar {
    // background-color: transparent;
    border: none; // 1px solid rgba(0, 0, 0, 0.12);
}

body .p-panel .p-panel-titlebar .fa {
    color: #333;
}

.ui-datepicker .ui-datepicker-title {
    color: #fbfbfb;
}

body .ui-corner-all {
    border-radius: 2px;
}

.topbar-username {
    line-height: 34px;
    color: white;
    font-size: 1.2em;
    margin-left: 10px;
}

.label-nonmd {
    font-size: 12px;
    color: #757575;
}

.label-required {
    /*font-weight:bold !important;*/
}

.label-required::after {
    content: ' *';
}

.global-searcher-close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
}

.global-searcher-bg {
    position: fixed;
    background-color: #757575;
    opacity: 0.8;
    height: 100%;
    width: 100%;
    z-index: 8000;
    top: 0;
    left: 0;
}

.global-searcher {
    //position:fixed;top:20px;right:20px;z-index:900;width:20%;
    z-index: 9000;
    position: fixed;
    width: 50%;
    //height: 200px;
    margin: 5% auto;
    /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    top: 50px;
    border: 1px solid #999;
    border-radius: 3px;
    //padding: 10px 30px 10px 10px;
    padding: 10px;
    background-color: #fefefe;
}

.global-searcher-hidden {
    display: none;
}

.global-searcher input {
    line-height: 30px;
    font-size: 25px;
}

.searcher-toolbar-toolbar > div {
    margin-bottom: 5px;
    border: none;
}

.searcher-table {
    margin-bottom: 5px;
}

// .ui-confirmdialog .ui-dialog-content .fa {
//     display: none;
// }

body .p-dialog .p-dialog-titlebar {
    padding: 1.5em 2em 1em 2em;
    color: $textColor;
}

body .p-dialog .p-dialog-titlebar .p-dialog-title {
    font-size: 24px;
    line-height: 26px;
    font-weight: 400;
}

.p-dialog .p-dialog-header {
    // background: #ededed;
    padding: 0.75rem;
}

.p-dialog .p-dialog-content {
    padding: 1.0rem 1.0rem 1.0rem 1.0rem;
}


.avix-form {
    label {
        color: #999;
    }
    .p-inputtext {
        border-color: #f3f3f3;
    }
}

.logo-text {
    color: white;
    font-size: 2em;
    text-align: center;
    line-height: 35px;
}

body .ui-datatable .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
    font-size: 1.25em;
    margin-left: 0px;
    margin-top: 2px;
}

.cfp-hotkeys-close {
    min-height: 1px !important;
    min-width: 1px !important;
}

.row-inaktiv {
    color: lightgray;
    font-style: italic;
}

.row-ungueltig {
    background-color: #ffcfcf !important;
    font-style: italic;
}

.row-inaktiv td {
    /*background-color: lightgray !important;*/
}

.ui-contextmenu {
    width: auto !important;
}

.mb-medium {
    margin-bottom: 10px;
}

// .ui-fluid .ui-inputgroup p-calendar > span,
// .ui-fluid .ui-inputgroup p-calendar .p-inputtext {
//     width: 100%;
// }

// .ui-fluid .ui-inputgroup p-calendar {
//     -webkit-box-flex: 1;
//     -ms-flex: 1 1 auto;
//     flex: 1 1 auto;
//     width: 1%;
//     bottom: -10px;
// }

// .ui-inputgroup p-calendar:not(:last-child) {
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//     border-right: 0 none;
// }

// body .ui-inputgroup p-calendar {
//     align-self: flex-end;
// }

// .ui-fluid .ui-inputgroup p-calendar {
//     width: 100%;
//     box-sizing: border-box;
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
// }

// body .ui-inputgroup .md-inputfield input:focus ~ label,
// body .ui-inputgroup .md-inputfield input.ui-state-filled ~ label,
// body .ui-inputgroup .md-inputfield .ui-inputwrapper-focus ~ label,
// body .ui-inputgroup .md-inputfield .ui-inputwrapper-filled ~ label {
//     top: -20px;
//     font-size: 12px;
// }

// body .md-inputfield input:focus ~ label,
// body .md-inputfield input.ui-state-filled ~ label,
// body .md-inputfield .ui-inputwrapper-focus ~ label,
// body .md-inputfield .ui-inputwrapper-filled ~ label {
//     top: -20px;
//     font-size: 12px;
//     color: #3e464c;
//     position: absolute;
// }

p-calendar.ng-dirty.ng-invalid .p-inputtext {
    border-bottom-color: #e62a10;
}

.ui-message-text {
    padding-left: 12px;
}

.form-group .p-inputtext,
.form-group button {
    width: 100%;
}

.form-group .ui-message.ui-messages-error {
    background-color: transparent;
    border: 0 none;
    margin: 0px;
    color: #e62a10;
    font-size: 0.75em;
}

.ui-datepicker .ui-datepicker-title {
    color: #f9f9f9;
}

.p-inputgroup .ui-calendar-button .fa,
.p-inputgroup-aviita .ui-calendar-button .fa {
    color: #acabab;
}

// .ui-inputgroup .ui-calendar-button,
// .ui-inputgroup-aviita .ui-calendar-button,
// .ui-inputgroup .ui-calendar-button:focus,
// .ui-inputgroup-aviita .ui-calendar-button:focus {
//     bottom: 1px;
//     right: 0;
//     background-color: transparent;
//     color: #212121;
//     height: 1.5em;
//     width: 1.5em;
//     box-shadow: none;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//     border-radius: 0;
// }

.p-button-red-icon .pi {
    color: rgb(180, 1, 1) !important;
}

.p-button-orange-icon .pi {
    color: rgb(209, 188, 0) !important;
}

.p-button-green-icon .pi {
    color: #4caf50 !important;
}

.p-button-black-icon .pi {
    color: #222 !important;
}

// body .ui-fluid .ui-inputgroup .p-button-icon-only {
//     width: 1.45em;
//     height: 1.45em;
//     padding: 0;
// }

// body .ui-inputgroup .ui-inputgroup-addon {
//     padding: 0 0 2px 0;
//     border: none;
//     color: #757575;
//     align-self: flex-end;
//     background: transparent no-repeat;
//     // background-image: linear-gradient(to bottom, #114b97, #114b97), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
//     // background-size: 0 2px, 100% 1px;
//     // background-position: 50% 100%, 50% 100%;
// }

// .ui-inputgroup .ui-inputgroup-addon {
//     display: flex;
// }

.no-background-color {
    background-color: transparent !important;
}

.layout-container .layout-main {
    padding: 65px 10px 10px 10px;
}

.nopad-force {
    padding: 0 !important;
}

.ui-fileupload-buttonbar {
    padding: 0;
    border: 0px;
}

.ui-fileupload-content {
    padding: 0;
    border: 0px;
}

body .ui-fileupload .ui-fileupload-buttonbar {
    padding: 0;
    border: 0px;
}

body .ui-fileupload .ui-fileupload-content {
    padding: 0;
    border: 0px;
}

.ui-fileupload .ui-widget-content .pi {
    color: #fff;
}

.ui-fileupload {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding-bottom: 6px;
    padding-top: 6px;
}

.p-fileupload-buttonbar p-button:nth-of-type(1) button {
    background: green;
}

.p-fileupload-buttonbar p-button:nth-of-type(1) button:enabled:focus {
    background: rgb(0, 165, 0);
}

.p-fileupload-buttonbar p-button:nth-of-type(1) button:enabled:hover {
    background: rgb(0, 165, 0);
}

.p-fileupload-buttonbar p-button:nth-of-type(2) button {
    background: rgb(224, 0, 0);
}

.p-fileupload-buttonbar p-button:nth-of-type(2) button:enabled:focus {
    background: rgb(255, 72, 72);
}

.p-fileupload-buttonbar p-button:nth-of-type(2) button:enabled:hover {
    background: rgb(255, 72, 72);
}


.w-100p {
    width: 100%;
}

@media (max-width: 641px) {
    .w-50p-m {
        width: 50%;
    }

    .w-100p-m {
        width: 100%;
    }

    .ui-toolbar-group-left,
    .ui-toolbar-group-right {
        float: none;
    }
}
.no-pad {
    padding: 0 !important;
}

.nobg {
    background-color: #fff !important;
}

.master-checkbox .ui-chkbox-label {
    font-weight: bold;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: black;
    border-color: transparent;
    background: transparent;
    position: absolute;
    right: 0px;
}

.p-autocomplete-dd .p-autocomplete-dropdown:hover,
.p-autocomplete-dd .p-autocomplete-dropdown:active,
.p-autocomplete-dd .p-autocomplete-dropdown:focus {
    color: black;
    border-color: transparent;
    background: transparent;
}

.p-autocomplete-dd input[type=search]::-webkit-search-cancel-button {
    position: relative;
    right: 20px;
}

// .ui-datatable table {
//     border-collapse: collapse;
//     width: 100%;
//     table-layout: auto;
// }

.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.425em 0.375rem;
}

.p-datatable thead th.no-pad,
.p-datatable tfoot td.no-pad,
.p-datatable tfoot th.no-pad {
    padding: 0.425em 0.375em !important;
}

.p-datatable tbody td.no-pad div {
    padding: 0.425em 0.375em;
}

.datatable-barchart {
    display: grid;
    padding: 0 !important;
}

.datatable-barchart-text {
    grid-column: 1;
    grid-row: 1;
}

.datatable-barchart-bar {
    grid-column: 1;
    grid-row: 1;
    margin: 2px 3px 2px 3px;
}

// .ui-table table {
//     border-collapse: collapse;
//     width: 100%;
//     table-layout: auto;
//     border-bottom: 1px solid #cacaca;
// }

.h-2em {
    height: 2em !important;
}

.vh-100 {
    height: 100vh;
}

.vh-90 {
    height: 90vh;
}

.vh-80 {
    height: 80vh;
}

.vh-75 {
    height: 75vh;
}

.vh-70 {
    height: 70vh;
}

.vh-60 {
    height: 60vh;
}

.vh-50 {
    height: 50vh;
}

.vh-40 {
    height: 40vh;
}

.vh-30 {
    height: 30vh;
}

.vh-20 {
    height: 20vh;
}

.vh-10 {
    height: 10vh;
}

.vh-fill-controller {
    height: calc(100vh - 15.5em);
}

.vh-fill-controller-btn {
    height: calc(100vh - 30.2em);
}

.docsearcher {
    height: calc(100vh - 30.4em);
    overflow: auto;
}

.vh-fill-controller-panel {
    height: calc(100vh - 18.5em);
}

.fill-height {
    height: 100%;
}

.height-minus-topbar {
    height: calc(100vh - 105px);
}

.height-minus-topbar-and-title {
    height: calc(100vh - 119px);
}

.gridster-toolbar-button-drag {
    position: absolute !important;
    right: 10px !important;
    top: 5px !important;
    transform: scale(0.75);
}

.gridster-toolbar-button-maximize {
    position: absolute !important;
    right: 10px !important;
    top: 5px !important;
    transform: scale(0.75);
    cursor: pointer;
}

.gridster-item-fix {
    padding-top: 22px;
    margin-bottom: 0px;
}

.gridster-maximize {
    height: calc(100%) !important;
    width: calc(100%) !important;
    top: 0 !important;
    left: 0 !important;
    transform: translate3d(0, 0px, 0px) !important;
    z-index: 999 !important;
}

.ui-paginator-bottom {
    border-top: 0 none !important;
    border-left: none !important;
    border-right: none !important;
    line-height: 20px;
    background-color: white !important;
}

.title-icon {
    padding: 0 14px;
}

.date-arrow-buttons {
    display: inline-flex;
    align-items: flex-end;
    padding-left: 6px;
}

.title-spacer {
    flex: 1 1 auto;
}

body .ui-dropdown {
    padding-bottom: 0px;
}

.searcher-icon {
    position: relative;
    top: 2px;
}

.valign-center {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}

.background-transparent {
    background: transparent !important;
}

.mat-card-header {
    background-color: #e2e2e2 !important;
    padding: 10px 5px 0px 5px !important;
    border-bottom: thin solid #9e9e9e;
}

.mat-card {
    padding: 0 !important;
}

.mat-card-content {
    padding: 0px !important;
    margin: 0px !important;
}

.mat-expansion-panel-body {
    padding: 0px !important;
}

.mat-chip {
    font-size: 12px;
}

.mat-standard-chip {
    min-height: 25px !important;
}

.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description,
.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title {
    color: $textColor;
}

.mat-expansion-panel-header {
    padding: 2px 12px 0px 8px !important;
}

.mat-expansion-panel-header-title {
    color: $textColor;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
    color: $textColor;
}

.mat-card-title {
    font-size: 14px;
    font-weight: 400;
}

.mat-card-content,
.mat-card-subtitle {
    font-size: 12px;
}

.mat-expansion-panel-content {
    font-size: 12px;
}

.ui-g.form-group > div {
    padding: 0.7em 0.4em;
}

.ui-g.form-group-m > div {
    padding: 0.7em 0.4em;
}

.mat-tab-link {
    height: 32px !important;
    padding: 0 14px !important;
    min-width: 0px !important;
}

.mat-tab-label,
.mat-tab-link {
    font-size: 14px !important;
}

.mat-tab-link-active,
.mat-tab-label-active {
    background-color: #eeeeee;
    border-bottom: 1px solid #fff;
    font-weight: bold;
}

.mat-tab-link,
.mat-tab-label {
    line-height: 30px !important;
    height: 30px !important;
    min-width: 100px !important;
}

.subheader {
    background-color: #f9f9f9;
    padding: 10px;
    border-bottom: thin solid #cccccc;
}

.test {
    font-size: 12px;
    line-height: 20px;
    color: #555555 !important;
}

.test2 {
    font-size: 12px;
    line-height: 20px;
    height: 30px;
}

.lnk {
    color: #2b58a0;
    text-decoration: underline;
}

.avi-dialog {
    max-width: 70% !important;
    max-height: 95vh !important;
}

@media (max-width: 641px) {
    .avi-dialog {
        max-width: 100% !important;
        max-height: 95vh !important;
    }
}
.nsm-content {
    padding: 0 !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-auto {
    overflow: auto !important;
}

.no-border {
    border: none !important;
}

.no-border-right {
    border-right: none !important;
}

.no-border-left {
    border-left: none !important;
}

.border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.no-box-shadow {
    box-shadow: none !important;
}

body .ui-inputgroup .md-inputfield label {
    top: 0px;
}

.presence-list {
    list-style-type: none;
    margin: 28px 0 0 0;
    padding: 0;
}

.presence-list li {
    padding: 8px 14px;
    border-bottom: 1px solid #d8dae2;
    position: relative;
}

.status.online:before {
    background-color: #94e185;
    border-color: #78d965;
    box-shadow: 0px 0px 4px 1px #94e185;
}
.status.offline:before {
    background-color: #c9404d;
    border-color: #c42c3b;
    box-shadow: 0px 0px 4px 1px #c9404d;
}
.status:before {
    content: ' ';
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 10px;
    border: 1px solid #000;
    border-radius: 7px;
}

.dashboard .timeline > .ui-g .ui-g-9 .event-text {
    color: #757575;
    font-size: 12px;
    display: block;
    padding-bottom: 10px;
    line-height: 10px;
    padding-top: 10px;
}

.dashboard .timeline > .ui-g .ui-g-3 {
    font-size: 14px;
    position: relative;
    border-right: 1px solid #bdbdbd;
    padding-bottom: 0px;
    margin-bottom: 10px;
}

.event-box {
    border-bottom: 1px dashed #eee;
    padding: 0 0 0 0 !important;
}

.p-datatable {
    line-height: 25px;
    //    border: 1px solid #bdbdbd;
    border-radius: 5px;
    //    box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    //    padding: 20px;
}

.back-button {
    width: 1.5em !important;
    height: 1.5em !important;
}

.lh-initial {
    line-height: initial;
}

.lh-10 {
    line-height: 10px;
}

.lh-20 {
    line-height: 20px;
}

.p-float-label > label {
    font-size: 1rem;
}

.favorite-color {
    color: yellow !important;
}

.ui-widget.read-only:disabled {
    opacity: 1;
    background-color: #dddddd;
}

.ui-treenode-content:focus {
    outline: none;
}

.ui-tree {
    height: 100%;
    width: auto;
}

.preview-area {
    display: block;
    white-space: pre-wrap;
    width: 100%;
    height: 100%;
    padding: 12px;
    box-sizing: border-box;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #3e3e3e;
}

.text-preview-area {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    background: #ffffff;
    padding: 1em;
    margin-bottom: 1em;
    box-sizing: border-box;
}

.image-preview-area {
    width: -webkit-calc(100% - 2px);
    width: -moz-calc(100% - 2px);
    width: calc(100% - 2px);
    border: 1px solid #000;
}

body .ui-widget-content {
    background-color: #ffffff;
    font-size: 1em;
    // border: 1px solid #bdbdbd;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.font-size-medium {
    font-size: medium;
}

.ui-table-caption {
    border-bottom: 0 none;
    padding: 0 !important;
    border-top: 0 none !important;
}

.ui-table-caption-vis {
    padding: 0.425em 0.375em !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

body .ui-table .ui-table-thead > tr > th,
body .ui-table .ui-table-tfoot > tr > td,
body .p-datatable .p-datatable-thead > tr > th,
body .p-datatable .p-datatable-tfoot > tr > td {
    font-weight: bold;
    color: darkslategrey;
    background-color: white;
    border: 1px solid #cacaca;
    // font-variant: all-small-caps;
    border-top-width: 0px;
    // font-size: small;
    text-align: left;
}

.p-datatable .p-datatable-thead > tr > th.text-right {
    text-align: right;
}

body .p-datatable .p-datatable-tbody > tr.odd {
    background-color: #f4f4f4;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight,
body .p-datatable .p-datatable-tbody > tr.odd.p-highlight {
    background-color: #dff1ff;
    color: rgba(0, 0, 0, 0.87);
}

.table-groupheader {
    background-color: #d9d9d9 !important;
    font-weight: bold;
}

.table-groupfooter {
    font-weight: bold;
}

// body .ui-table .ui-table-tbody > tr > td {
//     border: none;
// }

.mat-chip.mat-standard-chip {
    background-color: #ebebeb;
    color: rgba(0, 0, 0, 0.87);
}

// .multi-select-button {
//     height: 2.25em;
//     padding: 0.5em 1em;
//     border: 0 none !important;
//     box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
//     transition: background-color 0.3s;
//     vertical-align: middle;
//     height: 100% !important;
// }

// .multi-select-button .ui-multiselect-trigger {
//     height: 100% !important;
//     top: 0 !important;
// }

// .multi-select-button .ui-state-highlight {
//     background-color: white !important;
//     color: black !important;
// }


.multi-select-button {
    line-height: initial;
}

.p-multiselect.multi-select-button .p-multiselect-label {
    padding: 0;
    color: black;
}

.p-multiselect.multi-select-button {
    // border: 0 none;
    background-image: none;
    background: #eaeaea no-repeat;
    transition: background-color 0.3s;
    vertical-align: middle;
    border: none; // 1px solid rgba(0, 0, 0, 0.12);
}

.p-multiselect.multi-select-button:not(.p-disabled):hover {
    background: #dddddd no-repeat;
}

.p-button .p-multiselect-trigger {
    justify-content: flex-end;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
    color: black;
    background: #eaeaea;
    border: 0 none;
}

.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
    background: #dddddd;
    color: black;
    border-color: transparent;
}

.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
    background: #dddddd;
}

.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
    background: #dddddd;
}

.nobackground-image {
    background-image: none !important;
}

.e-richtexteditor .e-rte-content .e-content:read-only {
    padding-left: 0;
    padding-right: 0;
    min-height: 0px;
}

.e-dialog .e-dlg-content {
    font-size: 12px;
}

.separator {
    margin-top: 5px;
    border-bottom: thin solid #dadada;
    margin-bottom: 5px;
}

.separator-nomargin {
    border-bottom: thin solid #dadada;
}

.separator-thick {
    margin-top: 5px;
    border-bottom: 4px solid #dadada;
    margin-bottom: 5px;
}

.p-button-flat {
    box-shadow: none !important;
    background-color: transparent !important;
}

.p-button-flat:enabled:hover {
    background-color: #cccccc !important;
}

.p-button {
    padding: 0.443rem 1.0rem;
}

.p-button.p-button-icon-only {
    padding: 0.443rem;
}

.p-inputgroup .p-button.p-button-icon-only {
    padding: 0.35rem;
}

.p-button.p-button-outlined {
    padding: 0.37rem 1.0rem;
}

.ui-spinner .ui-spinner-button {
    border: none;
}

.avi-dialog-contents > * {
    padding: 0 !important;
}

.mat-tab-header-autohide > .mat-tab-header {
    display: none;
}

body .field-readonly p-dropdown .ui-dropdown-trigger .pi,
body .field-readonly p-spinner .ui-fluid .ui-spinner .ui-spinner-button .pi:read-only {
    display: none;
}

body .ui-dropdown.ui-state-disabled .ui-dropdown-trigger,
body .ui-fluid .ui-spinner:disabled .ui-spinner-button .pi {
    display: none;
}

body .p-inputtext:read-only {
    background-image: none !important;
}

.ui-fluid .field-readonly p-dropdown div {
    background-image: none !important;
}

body .ui-widget:disabled,
body .ui-state-disabled {
    opacity: 0.55;
    filter: Alpha(Opacity=55);
    cursor: auto !important;
}

button.ui-spinner-button:disabled {
    display: none;
}

.view-header {
    font-size: 20px;
}

body .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
    outline: none;
}

.view-panel .p-panel-titlebar .p-panel-title {
    font-weight: bold;
}

// .p-button-icon-only .p-button-icon-left,
// .p-button-text-icon-left .p-button-icon-left,
// .p-button-text-icon-right .p-button-icon-right {
//     margin-top: -0.46em;
// }

// .pi {
//     font-size: 1.5em;
// }

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-multiselect-filter-container .p-inputtext {
    color: black;
}

body .ui-multiselect-panel.ui-widget .ui-multiselect-header .ui-multiselect-close span {
    color: black;
}

body .ui-widget-header:not(.ui-dataview-header) .p-inputtext {
    background-image: linear-gradient(to bottom, #3e464c, #3e464c), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
}

.mat-header-submodul {
    background-color: #f9f9f9;
}



.summary-label {
    font-weight: bold;
    color: #757575;
    // font-variant: all-small-caps;
    font-size: 13px;
}

.pep-marker {
    position: absolute;
    right: 10px;
    font-weight: bold;
    font-size: 13px;
    color: red;
}

.light-accordion .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover,
.light-accordion .ui-accordion .ui-accordion-header.ui-state-default,
.light-accordion .ui-accordion .ui-accordion-header.ui-state-active,
.light-accordion .ui-accordion .ui-accordion-header.ui-state-default > a,
.light-accordion .ui-accordion .ui-accordion-header.ui-state-active > a {
    background-color: white;
    color: #b44a19da;
    font-weight: bold;
}

.light-accordion .ui-state-active .pi,
.light-accordion .ui-state-highlight .pi {
    color: #b44a19da;
}

.light-accordion .ui-accordion .ui-accordion-header {
    margin-top: 0px;
}

.light-accordion .ui-widget-content {
    border: none;
    margin-bottom: 16px;
}

.light-accordion .ui-accordion .ui-accordion-content {
    padding: 0;
}

.inherit-background {
    background: inherit;
}

.form-field {
    font-size: 1.1em;
    width:100%;
}

.form-field-checkbox {
    display: inline-block;
}

body .p-inputtext:disabled {
    border-bottom: none;
    opacity: 0.55;
    filter: Alpha(Opacity=55);
    cursor: auto !important;
}

// ******** AJU Spezifische Einstellungen ********

// Navigation: #2D545E
// Accent: #C89666
// Header: #FDF8F2

.shift-searcher {
    position: relative;
    top: -30px;
}

.mat-tab-link-container {
    background: #07307d;
    padding: 8px;
}

.mat-expansion-panel-header.mat-expanded {
    height: 54px;
}

.mat-tab-label,
.mat-tab-link {
    color: white;
}

.mat-tab-link-active,
.mat-tab-label-active {
    background-color: transparent;
    // border-bottom: 1px solid #6b7f7f;
    font-weight: bold;
}

.mat-tab-link {
    opacity: 1 !important;
}

.mat-tab-secondary .mat-tab-link-container,
.mat-tab-admin .mat-tab-secondary .mat-tab-link-container {
    background: white;
}

.mat-tab-admin .mat-tab-link-container {
    background: darkred;
}

.mat-tab-secondary .mat-tab-label,
.mat-tab-secondary .mat-tab-link {
    color: black;
    font-size: 13px !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: white;
}

.mat-tab-secondary .mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-secondary .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #333;
}

.mat-tab-secondary .mat-tab-link-container {
    padding: 0px;
}

.mat-tab-tertiary .mat-tab-header {
    border-bottom: none;
}

.titel {
    padding-left: 32px;
    font-size: 24px;
    font-weight: normal;
}

.mat-toolbar.mat-primary {
    background: #eeeeee;
    color: black;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.mat-toolbar-row {
    height: var(--header-height) !important;
    background-color: $headerBackgroundColor;
}

.vwbp-router-outlet {
    min-height: calc(100vh - var(--header-height));
}

.logoimage {
    height: 80px;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimizequality;
}

.example-spacer {
    flex: 1 1 auto;
}

.mat-expansion-panel {
    background: transparent;
}

body {
    background-color: white;
}

.mat-drawer-container {
    background-color: transparent;
}

.card-header {
    padding: 5px 15px 0px 15px !important;
    background-color: white !important;
}

.mat-expansion-panel-header-title {
    color: $textColor;
    font-size: 24px;
    line-height: 30px;
}

.card-contents {
    padding: 0px 15px 15px 15px;
}

.p-panel .p-panel-content {
    padding: 0 1rem 1rem 1rem;
}

.modul {
    padding: 20px;
    background: white;
}

.card-container {
    padding: 10px;
}

.awf-page-icon {
    visibility: hidden;
    width: 0px;
    padding: 0px;
}

.p-field {
    margin-bottom: 0rem;
}

.ui-panel-content-wrapper {
    // border: 1px solid rgba(0, 0, 0, 0.12);
    // border-radius: 5px;
}

body .p-panel .panel-header {
    color: $textColor; // black;
    font-weight: bold;
    // font-size: 16px;
}

body .p-panel-header {
    align-items: flex-start;
}

body .p-panel .p-panel-header .p-panel-title {
    font-weight: bold;
    font-size: 16px;
    padding-top: 5px;
}

body .ui-table .ui-sortable-column.ui-state-highlight {
    background-color: unset;
    color: unset;
    border-top-color: #cacaca;
    color: #175da8;
}

avi-core-sorticon .pi {
    font-size: 1.1em;
}

body .ui-state-active .pi,
body .ui-state-highlight .pi {
    color: unset;
}

body .ui-message {
    padding: 7px 5px 5px 5px;
    margin: 0;
}

p-message.w-100p div {
    width: 100%;
}

.p-inline-message {
    justify-content: left !important;
}

.label-bold {
    font-weight: bold;
}

body p-checkbox {
    display: flex;
}

.inline-block {
    display: inline-block;
}

.checkbox-inline {
    display: inline-block;
}

.red-border {
    border: red 2px solid;
    padding: 5px;
    border-radius: 5px;
}

.inputtextarea-with-min-height{
    min-height: 158px;
}

// body .p-inputtext.ng-dirty.ng-invalid,
// body p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
// body p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
// body p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext,
// body p-chips.ng-dirty.ng-invalid > .p-inputtext,
// body p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
// body p-checkbox.ng-dirty.ng-invalid .p-chkbox-box,
// body p-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box,
// body p-inputswitch.ng-dirty.ng-invalid .p-inputswitch,
// body p-listbox.ng-dirty.ng-invalid .p-inputtext,
// body p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
// body p-spinner.ng-dirty.ng-invalid > .p-inputtext,
// body p-selectbutton.ng-dirty.ng-invalid .p-button,
// body p-togglebutton.ng-dirty.ng-invalid .p-button {
//     background-image: linear-gradient(to bottom, #ffcdd2, #ffcdd2), linear-gradient(to bottom, #ffcdd2, #ffcdd2);
// }
.version-dropdown {
    text-align: center;
}

.version-dropdown .p-dropdown {
    width: 28em;
    text-align: left;
}

.ui-confirmdialog,
.p-confirm-dialog {
    width: auto;
    max-width: 75%;
    line-height: 20px;
}

.hidden {
    display: none;
}

body .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
    padding-bottom: 0px;
}
body .ui-dropdown:not(.ui-state-disabled).ui-state-focus .ui-dropdown-label {
    padding-bottom: 0px;
}

:focus {
    outline: none;
}

td .p-checkbox,
tr .p-checkbox {
    vertical-align: middle;
}

.p-datatable-scrollable-footer {
    visibility: hidden;
}

// .p-inline-message.p-inline-message-error.p-inline-message-black  {
//     background-color: rgb(75, 75, 75);
//     border: solid transparent;
//     border-width: 1px;
//     color: white;
// }

// .p-inline-message.p-inline-message-error.p-inline-message-black .p-inline-message-icon {
//     color: white;
// }

.p-message-black {
    background-color: rgb(75, 75, 75);
    border: solid transparent;
    border-width: 0;
    color: white;
}

.p-message-warn-red {
    background: #FFCDD2;
    border: solid transparent;
    border-width: 0;
    color: #B71C1C;
}

.p-message-warn-yellow {
    background: #fdd463;
    border: solid transparent;
    border-width: 0;
    color: #5f4101;
}

.p-message .p-message-detail {
    font-size: 1rem;
}

.p-message .p-message-wrapper {
    padding: 0.75rem 0.75rem;
}

.p-message .p-message-icon {
    font-size: 1.2rem;
    margin-right: 0.5rem;
}

.p-badge.schwaerzen {
    background-color: #4b4b4b;
    margin-right: 4px;
    margin-left: 4px;
}

.p-badge.comment,
.p-badge.unstimmigkeit-open {
    background-color: #fa7b7b;
    margin-right: 4px;
    margin-left: 4px;
}

.p-badge.comment-empty,
.p-badge.unstimmigkeit-closed {
    background-color: #cacaca;
    margin-right: 4px;
    margin-left: 4px;
}

.comment-badge {
    background-color: #fa7b7b;
    color: white;
    padding: 0px 6px;
    border-radius: 50%;
    font-style: normal;
}

.comment-badge-empty {
    background-color: #cacaca;
    color: white;
    padding: 0px 6px;
    border-radius: 50%;
    font-style: normal;
}

.rt-inaktiv {
    color: rgb(170, 170, 170) !important;
    // font-style: italic;
}

.rt-geloescht {
    color: rgb(170, 170, 170) !important;
    // text-decoration: line-through;
}

tr.rt-geloescht {
    position: relative;
}

tr.rt-geloescht td:first-child::before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 0.5px solid rgb(170, 170, 170);
    width: 100%;
}

.rt-softselection {
    background-color: #dff1ff !important;
}

.form-field .history-highlight .p-inputtext,
.form-field .history-highlight.p-inputtext,
.form-field .history-highlight .ui-dropdown {
    background-color: khaki;
}

.table-emptymessage-text {
    font-style: italic;
}

.form-title {
    font-size: large;
}

ejs-dialog .mat-expansion-panel-header.mat-expanded {
    height: 40px;
}


.p-steps {
    border-bottom: solid 1px lightgrey;
    background: #fafafa;
}

.button-right {
    margin-left: auto;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.column-verlaufkommentar {
    display: block !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 30vw;
}

/*body .ui-dialog .ui-dialog-footer,
body .p-dialog .p-dialog-footer {
    padding-top: 12px;
}*/

.one-line-ellipsis {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.p-message {
    margin: 0 0 0.75rem 0;
}

.ui-tooltip-large {
    max-width: 400px;
    white-space: pre-wrap;
}

.current-user-info-box-small {
    position: absolute;
    top: 2px;
    right: 4px;
    font-size: smaller;
    color: #666;
    z-index: 999;
    text-align: right;
}

body .p-progressbar .p-progressbar-label {
    line-height: 1.5em;
    color: #495057;
}

body .p-progressbar .p-progressbar-value {
    height: 1.5em;
    background-color: #2196F3;
}

body .p-progressbar {
    height: 1.5em;
    background-color: rgb(228, 227, 227) !important;
    overflow: hidden;
    border: 0 none;
}


.sidenav-container {
    height: 100%;
}

.label-required::after {
    color: red;
}

body .ui-messages .ui-messages-icon,
.ui-messages.ui-messages-success,
.ui-messages.ui-messages-warn,
.ui-messages.ui-messages-error,
.ui-message-success .ui-message-icon,
.ui-message-warn .ui-message-icon,
.ui-message-error .ui-message-icon {
    color: white !important;
    margin-top: 1px;
}

.p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
    font-size: 11px;
}

.splash-screen {
    background-color: #fff;
    height: 100%;

    .splash-container {
        padding-top: 250px;
        width: 350px;
        margin: 0 auto;
        text-align: center;
        font-size: 24px;

        .logo {
            display: inline-block;
            vertical-align: middle;
            width: 200px;
            height: 35px;
            // background: url('../images/aviita_logo.png') top center no-repeat;
            background-size: 119px 35px;
        }
    }

    .load-bar {
        position: relative;
        margin-top: 20px;
        width: 100%;
        height: 6px;
        background-color: #ffffff;
    }

    .bar {
        content: '';
        display: inline;
        position: absolute;
        width: 0;
        height: 100%;
        left: 50%;
        text-align: center;
    }
    .bar:nth-child(1) {
        background-color: #114B97;
        animation: loading 3s linear infinite;
    }
    .bar:nth-child(2) {
        background-color: #00838f;
        animation: loading 3s linear 1s infinite;
    }
    .bar:nth-child(3) {
        background-color: #4dd0e1;
        animation: loading 3s linear 2s infinite;
    }
    @keyframes loading {
        from {
            left: 50%;
            width: 0;
            z-index: 100;
        }
        33.3333% {
            left: 0;
            width: 100%;
            z-index: 10;
        }
        to {
            left: 0;
            width: 100%;
        }
    }
}





// ... Irgendwie fehlt das in den Styles von Version 13

.p-panel .p-panel-icons-end {
    order: 2;
    margin-left: auto;
  }
  .p-panel .p-panel-icons-start {
    order: 0;
    margin-right: 0.5rem;
  }
  .p-panel .p-panel-icons-center {
    order: 2;
    width: 100%;
    text-align: center;
  }

  
.m-0p {
    margin: 0px;
}

.m-4p {
    margin: 4px;
}

.m-8p {
    margin: 8px;
}

.m-12p {
    margin: 12px;
}

.m-16p {
    margin: 16px;
}

.m-20p {
    margin: 20px;
}

.mr-0p {
    margin-right: 0px;
}

.mr-4p {
    margin-right: 4px;
}

.mr-8p {
    margin-right: 8px;
}

.mr-12p {
    margin-right: 12px;
}

.mr-16p {
    margin-right: 16px;
}

.mr-20p {
    margin-right: 20px;
}

.mb-0p {
    margin-bottom: 0px;
}

.mb-4p {
    margin-bottom: 4px;
}

.mb-8p {
    margin-bottom: 8px;
}

.mb-12p {
    margin-bottom: 12px;
}

.mb-16p {
    margin-bottom: 16px;
}

.mb-20p {
    margin-bottom: 20px;
}

.ml-0p {
    margin-left: 0px;
}

.ml-4p {
    margin-left: 4px;
}

.ml-8p {
    margin-left: 8px;
}

.ml-12p {
    margin-left: 12px;
}

.ml-16p {
    margin-left: 16px;
}

.ml-20p {
    margin-left: 20px;
}

.mt-0p {
    margin-top: 0px;
}

.mt-4p {
    margin-top: 4px;
}

.mt-8p {
    margin-top: 8px;
}

.mt-12p {
    margin-top: 12px;
}

.mt-16p {
    margin-top: 16px;
}

.mt-20p {
    margin-top: 20px;
}

.p-0p {
    padding: 0px;
}

.p-2p {
    padding: 2px;
}

.p-4p {
    padding: 4px;
}

.p-8p {
    padding: 8px;
}

.p-12p {
    padding: 12px;
}

.p-16p {
    padding: 16px;
}

.p-20p {
    padding: 20px;
}

.p-5emp {
    padding: 0.5em;
}

.pr-0p {
    padding-right: 0px;
}

.pr-4p {
    padding-right: 4px;
}

.pr-8p {
    padding-right: 8px;
}

.pr-10p {
    padding-right: 10px;
}

.pr-12p {
    padding-right: 12px;
}

.pr-15p {
    padding-right: 15px;
}

.pr-16p {
    padding-right: 16px;
}

.pr-20p {
    padding-right: 20px;
}

.pr-32p {
    padding-right: 32px;
}

.pb-0p {
    padding-bottom: 0px;
}

.pb-2p {
    padding-bottom: 2px;
}

.pb-4p {
    padding-bottom: 4px;
}

.pb-8p {
    padding-bottom: 8px;
}

.pb-12p {
    padding-bottom: 12px;
}

.pb-15p {
    padding-bottom: 15px;
}

.pb-16p {
    padding-bottom: 16px;
}

.pb-20p {
    padding-bottom: 20px;
}

.pl-0p {
    padding-left: 0px;
}

.pl-2p {
    padding-left: 2px;
}

.pl-4p {
    padding-left: 4px;
}

.pl-8p {
    padding-left: 8px;
}

.pl-10p {
    padding-left: 10px;
}

.pl-12p {
    padding-left: 12px;
}

.pl-15p {
    padding-left: 15px;
}

.pl-16p {
    padding-left: 16px;
}

.pl-20p {
    padding-left: 20px;
}

.pl-32p {
    padding-left: 32px;
}

.pt-0p {
    padding-top: 0px;
}

.pt-2p {
    padding-top: 2px;
}

.pt-4p {
    padding-top: 4px;
}

.pt-8p {
    padding-top: 8px;
}

.pt-12p {
    padding-top: 12px;
}

.pt-16p {
    padding-top: 16px;
}

.pt-20p {
    padding-top: 20px;
}

.pdf-viewer-container {
    overflow: hidden !important;
}

.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
    left: 0px;
}


.p-column-resizer:after {
    content: ' ';
    position: absolute;
    top: 0%;
    left: 100%;
    background: #0000001a;
    height: 100%;
    width: 0.5px;
}

.p-progressbar-determinate .p-progressbar-value-animate {
    transition: width 0.1s ease-in-out !important;
}

.flex-grow-2 {
    flex-grow: 2 !important;
}

.flex-grow-3 {
    flex-grow: 3 !important;
}

.flex-gap-1 {
    gap: 0.25rem;
}

.flex-gap-2 {
    gap: 0.5rem;
}

.base-form-container {
    margin-left: -0.5rem;
}

.doc-preview-dialog {
    height: 95vh;
}