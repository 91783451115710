p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
        @include invalid-input();
}

.p-password-panel {
    padding: $panelContentPadding;
    background: $panelContentBg;
    color: $panelContentTextColor;
    border: $overlayContentBorder;
    box-shadow: $inputOverlayShadow;
    border-radius: $borderRadius;

    .p-password-meter {
        margin-bottom: $inlineSpacing;
        background: $passwordMeterBg;

        .p-password-strength {
            &.weak {
                background: $passwordWeakBg;
            }

            &.medium {
                background: $passwordMediumBg;
            }

            &.strong {
                background: $passwordStrongBg;
            }
        }
    }
}
