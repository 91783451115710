$primaryTextColor:#FFFFFF;
$primaryColor: #114b97;
$primaryDarkColor: #062b70;
$primaryLightColor: #1455ad;

$accentTextColor: #FFFFFF;
$accentColor: #222;
$accentDarkColor: #111;
$accentLightColor: #444;



$modulColor: rgb(245, 240, 234);
$primaryLightestColor: #F9FBE7;
$primaryMenuTextColor: #827717;




@import '../../sass/theme/theme_light';
